@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url('fonts/Lato-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  src: url('fonts/Lato-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: bold;
  font-style: italic;
  src: url('fonts/Lato-BoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: bold;
  src: url('fonts/Lato-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url('fonts/Lato-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: italic;
  src: url('fonts/Lato-LightItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 200;
  src: url('fonts/Lato-Thin.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 200;
  font-style: italic;
  src: url('fonts/Lato-ThinItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: url('fonts/Lato-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: italic;
  src: url('fonts/Lato-BlackItalic.ttf') format('ttf');
}

:root {
  --color-0: black;
  --color-50: #1F2937;
  --color-100: #111827;
  --color-200: #1F2937;
  --color-400: #374151;
  --color-500: #4b5563;
  --color-600: #6b7280;
  --color-700: #9ca3af;
  --color-800: #d1d5db;
  --color-850: #e5e7eb;
  --color-900: #f3f4f6;
  --color-1000: #f9fafb;

  --color-error: #d30000;
  --color-lighter-purple: #c2c8ff;
  --color-light-purple: #4F46E5;
  --color-purple: #4338CA;
  --color-purpler: #2e3788;
  --color-dark-purple: #3730A3;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

@media screen and (min-width: 768px) {
  html {
    margin-right: calc(100% - 100vw);
  }
}

body {
  font: 1.6rem 'Inter', sans-serif;
  margin: 0;
  color: black;
  overflow-x: hidden;
  background-color: white;
}